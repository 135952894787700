export const tiers = ["bronze", "silver", "gold", "platinum"] as const

export type Tier = typeof tiers[number]

export const tierValues = {
  platinum: {
    name: "platinum",
    required: "25.000",
    fees: "-100%",
    background: "var(--blue-20-v2)",
    freename: "220$",
    airdrop: "+25%",
    hasReport: true,
    smartTrades: 10,
    hasVip: true,
    taxReport: "-80%",
    freeDeposit: 3,
    weRoad: "450€",
    saily: "5GB",
    tiqets: "12%",
    builtDifferent: "30€",
    helloFresh: "155€",
    nordVpn: "-78%",
    staking: "+70% APY",
  },
  gold: {
    name: "gold",
    required: "10.000",
    fees: "-75%",
    background: "var(--yellow-20-v2)",
    freename: "180$",
    airdrop: "+20%",
    hasReport: true,
    smartTrades: 6,
    hasVip: false,
    taxReport: "-40%",
    freeDeposit: 2,
    weRoad: "300€",
    saily: "3GB",
    tiqets: "12%",
    builtDifferent: "20€",
    helloFresh: "155€",
    nordVpn: "-78%",
    staking: "+30% APY",
  },
  silver: {
    name: "silver",
    required: "5.000",
    fees: "-50%",
    background: "var(--grey-20-v2)",
    freename: "130$",
    airdrop: "+15%",
    hasReport: true,
    smartTrades: 4,
    hasVip: false,
    taxReport: "-20%",
    freeDeposit: 1,
    weRoad: "210€",
    saily: "1GB",
    tiqets: "10%",
    builtDifferent: "15€",
    helloFresh: "125€",
    nordVpn: "-75%",
    staking: "+15% APY",
  },
  bronze: {
    name: "bronze",
    required: "1.500",
    fees: "-30%",
    background: "var(--orange-20-v2)",
    freename: "100$",
    airdrop: "+10%",
    taxReport: "-10%",
    hasReport: false,
    smartTrades: 2,
    hasVip: false,
    freeDeposit: 1,
    weRoad: "150€",
    saily: "1GB",
    tiqets: "10%",
    builtDifferent: "10€",
    helloFresh: "125€",
    nordVpn: "-74%",
    staking: "+5% APY",
  },
}

export const tierKeys = Object.keys(tierValues) as Tier[]

export const tierArray = tierKeys.map(tier => tierValues[tier]).reverse()

export type TierValuesProps = typeof tierArray[number]

export const dynamicLinks = {
  main: "https://youngexchange.page.link/yngb",
  menu: "https://youngexchange.page.link/yngmenu",
  ios: "https://youngexchange.page.link/yngios",
  android: "https://youngexchange.page.link/yngand",
}
