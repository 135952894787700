import React from "react"
import { useComparativeContext } from "../context/ComparativeContext"
import { tiers, Tier, tierArray, tierValues, dynamicLinks } from "../constants"
import { Icon } from "@youngagency/young-ui"
import { HStack, VStack, Body, Subtitle, Box } from "@youngagency/ui"
import { StaticImage } from "gatsby-plugin-image"
import { Avatar, BlackButton, Chip } from "../../../components/layout"
import { useI18next } from "gatsby-plugin-react-i18next"
import { CardGrid } from "../../../components/cards"
import { ClubTooltips } from "./ClubTooltips"
import { navigate } from "@reach/router"
import { clubsEvents, globalEvents } from "../../../service/tracking"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { TableRow, ClubsRow, TableCell, TableCellContent } from "./Tabs"
import {
  desktopTable,
  desktopViewVisibility,
  mobileViewVisibility,
  tbody,
} from "../styles/tabs.css"

const ComparativeTabs = () => {
  const { tier, setTier } = useComparativeContext()
  return (
    <Box width="full" overflowX="scroll" marginTop={48}>
      <HStack
        atoms={{
          width: "full",
        }}
        spacing={16}
        style={{
          flexWrap: "nowrap",
          overflowX: "scroll",
          borderBottom: "1px solid var(--grey-50-v2)",
        }}
      >
        {tiers.map((t, i) => {
          const isActive = t === tier
          return (
            <HStack
              spacing="unset"
              color={isActive ? "var(--black)" : "var(--grey-200-v2)"}
              key={i}
              onClick={() => {
                setTier(t)
              }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                __width="fit-content"
                marginLeft={i === 0 ? 24 : 0}
                __color={isActive ? "var(--black)" : "var(--grey-200-v2)"}
                __height={"33px"}
                style={{
                  whiteSpace: "nowrap",
                  boxSizing: "content-box",
                  cursor: "pointer",
                  borderBottom: isActive ? "2px solid var(--black)" : "none",
                  borderColor: "var(--black)",
                }}
              >
                <Body
                  variant="1"
                  fontWeight={600}
                  style={{
                    color: "inherit",
                  }}
                >
                  {`Club ${t}`}
                </Body>
              </Box>
              <Box __height={"33px"} width={16} />
            </HStack>
          )
        })}
      </HStack>
    </Box>
  )
}

export const TierImage: React.FC<{ tier: Tier }> = ({ tier }) => {
  switch (tier) {
    case "bronze":
      return <StaticImage src="../images/tiers/Bronze.png" alt="Bronze" />
    case "silver":
      return <StaticImage src="../images/tiers/Silver.png" alt="Silver" />
    case "gold":
      return <StaticImage src="../images/tiers/Gold.png" alt="Gold" />
    case "platinum":
      return <StaticImage src="../images/tiers/Platinum.png" alt="Platinum" />
  }
}

export const MobileComparativeTable = () => {
  const { tierValues, tier } = useComparativeContext()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const { t, language } = useI18next()
  const handleClick = React.useCallback(() => {
    if (gaTracker) {
      gaTracker.trackMultipleEvents([
        {
          eventName: clubsEvents.club_body_comparative,
          payload: {
            card_clicked: tier,
          },
        },
        {
          eventName: globalEvents.click_to_exchange,
        },
      ])
    }
    navigate(dynamicLinks.main)
  }, [tier, gaTracker])
  return (
    <Box className={mobileViewVisibility}>
      <ComparativeTabs />
      <Box __maxWidth="calc(100% - 48px)" width="full" mx="auto" marginTop={24}>
        <VStack spacing={8} align="flex-start" justify="flex-start">
          <Avatar
            shape="square"
            maxWidth="64px"
            maxHeight="64px"
            width="100%"
            height="100%"
          >
            <TierImage tier={tier} />
          </Avatar>
          <Chip backgroundColor={tierValues.background} fontWeight={600}>
            {t(
              `clubs_page.comparative_section.tabs.columns.${tier}.label`
            ).toUpperCase()}
          </Chip>
          <Box>
            <Subtitle variant="1" fontWeight={600}>
              {t(`clubs_page.comparative_section.tabs.columns.${tier}.title`)}
            </Subtitle>
          </Box>
        </VStack>
        <Box marginTop={16} as="table" width="full">
          <Box as="tbody" className={tbody}>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <TableCellContent>
                  <HStack spacing={4} align="center">
                    <Subtitle
                      variant="2"
                      fontWeight={600}
                      color="grey600"
                      atoms={{
                        width: "full",
                      }}
                    >
                      {t(`clubs_page.comparative_section.tabs.title`)}
                    </Subtitle>{" "}
                  </HStack>
                </TableCellContent>
              </TableCell>
              <TableCell justifyContent="center"></TableCell>
            </TableRow>

            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_1.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_1.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_1.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_1.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.fees}
                </Body>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_13.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_13.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_13.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_percentage" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_13.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.staking}
                </Body>
              </TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_3.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_3.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_3.title_airdrop_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="rocket_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_3.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.airdrop}
                </Body>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_7.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_7.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_7.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_percentage" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          "clubs_page.comparative_section.tabs.rows.row_7.title"
                        )}
                      </Body>
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.freeDeposit}
                </Body>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_4.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_4.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_4.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="document_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_4.title`
                        )}{" "}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Icon
                  icon={tierValues.hasReport ? "done" : "close"}
                  color={tierValues.hasReport ? "var(--green-200-v2)" : "black"}
                  fontSize="24px"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_5.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_5.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_5.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="support_agent" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_5.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Icon
                  icon={tierValues.hasVip ? "done" : "close"}
                  color={tierValues.hasVip ? "var(--green-200-v2)" : "black"}
                  fontSize="24px"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_12.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_12.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_12.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="add_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_12.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                {tierValues.smartTrades ? (
                  <Body variant="1" fontWeight={600}>
                    {tierValues.smartTrades}
                  </Body>
                ) : (
                  <Icon icon="close" color="black" fontSize="24px" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_6.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_6.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_6.title_mobile"
                    className="toogle-tooltip"
                  >
                    <Icon icon="newsletter_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          "clubs_page.comparative_section.tabs.rows.row_6.title"
                        )}
                      </Body>
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                {tierValues.taxReport ? (
                  <Body variant="1" fontWeight={600}>
                    {tierValues.taxReport}
                  </Body>
                ) : (
                  <Icon icon="close" color="black" fontSize="24px" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <TableCellContent>
                  <HStack spacing={4} align="center">
                    <Subtitle
                      variant="2"
                      fontWeight={600}
                      color="grey500"
                      atoms={{
                        width: "full",
                      }}
                    >
                      {t(`clubs_page.comparative_section.tabs.partner_title`)}
                    </Subtitle>
                  </HStack>
                </TableCellContent>
              </TableCell>
              <TableCell justifyContent="center" />
            </TableRow>
             <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_10.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_10.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_10.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/nord-vpn.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_10.title`
                      )}/>
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_10.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.nordVpn}
                </Body>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_8.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_8.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_8.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/we-road.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_8.title`
                      )}/>
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_8.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.weRoad}
                </Body>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_11.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_11.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_11.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/saily.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_11.title`
                      )}/>
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_11.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.saily}
                </Body>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_14.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_14.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_14.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/tiqets.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_14.title`
                      )}/>
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_14.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.tiqets}
                </Body>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_15.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_15.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_15.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/built-different.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_15.title`
                      )}/>
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_15.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.builtDifferent}
                </Body>
              </TableCell>
            </TableRow>


            <TableRow>
              <TableCell __paddingLeft={10} __paddingRight={10}>
                <ClubTooltips
                  text={t(
                    `clubs_page.comparative_section.tabs.rows.row_2.description`
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_2.description_mobile"
                  tooltipPosition="bottom"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_2.title_mobile"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                        src="../images/partner/freename.png"
                        width={40}
                        height={40}
                        alt={t(
                          `clubs_page.comparative_section.tabs.rows.row_2.title`
                        )}
                      />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_2.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              <TableCell justifyContent="center">
                <Body variant="1" fontWeight={600}>
                  {tierValues.freename}
                </Body>
              </TableCell>
            </TableRow>

            {language === "it" ? (
              <TableRow>
                <TableCell __paddingLeft={10} __paddingRight={10}>
                  <ClubTooltips
                    text={t(
                      `clubs_page.comparative_section.tabs.rows.row_9.description`
                    )}
                    id="clubs_page.comparative_section.tabs.rows.row_9.description_mobile"
                    tooltipPosition="bottom"
                  >
                    <TableCellContent
                      id="clubs_page.comparative_section.tabs.rows.row_9.title_mobile"
                      className="toogle-tooltip"
                    >
                      <StaticImage
                        src="../images/partner/hello-fresh.png"
                        width={40}
                        height={40}
                        alt={t(
                          `clubs_page.comparative_section.tabs.rows.row_9.title`
                        )}
                      />
                      <HStack spacing={4} align="center">
                        <Body
                          variant="1"
                          fontWeight={600}
                          atoms={{
                            width: "full",
                          }}
                        >
                          {t(
                            `clubs_page.comparative_section.tabs.rows.row_9.title`
                          )}
                        </Body>{" "}
                        <Icon
                          icon="info_fill"
                          fontSize="16px"
                          color="var(--grey-200-v2)"
                        />
                      </HStack>
                    </TableCellContent>
                  </ClubTooltips>
                </TableCell>
                <TableCell justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues.helloFresh}
                  </Body>
                </TableCell>
              </TableRow>
            ) : null}
          </Box>
        </Box>

        <BlackButton mt="24px" isFullWidth onClick={handleClick}>
          {t(`clubs_page.comparative_section.tabs.columns.${tier}.cta_text`)}
        </BlackButton>
      </Box>
    </Box>
  )
}

export const DesktopComparativeTable = () => {
  const { t, language } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleClick = React.useCallback((tier: Tier) => {
    if (gaTracker) {
      gaTracker.trackMultipleEvents([
        {
          eventName: clubsEvents.club_body_comparative,
          payload: {
            card_clicked: tier,
          },
        },
        {
          eventName: globalEvents.click_to_exchange,
        },
      ])
    }
    navigate(dynamicLinks.main)
  }, [])

  return (
    <Box className={desktopViewVisibility}>
      <CardGrid>
        <ClubsRow>
          <Box />
          {tierArray.map(tierValue => (
            <VStack
              spacing={8}
              align="flex-start"
              justify="flex-start"
              atoms={{
                marginTop: 48,
              }}
            >
              <Avatar
                shape="square"
                maxWidth="64px"
                maxHeight="64px"
                width="100%"
                height="100%"
              >
                <TierImage tier={tierValue.name as Tier} />
              </Avatar>
              <Chip background={tierValue.background} fontWeight={600}>
                {t(
                  `clubs_page.comparative_section.tabs.columns.${tierValue.name}.label`
                ).toUpperCase()}
              </Chip>
              <Box>
                <Subtitle variant="1" fontWeight={600}>
                  {t(
                    `clubs_page.comparative_section.tabs.columns.${tierValue.name}.title`
                  )}
                </Subtitle>
              </Box>
            </VStack>
          ))}
        </ClubsRow>
        <Box as="table" width="full">
          <Box as="tbody" marginTop={16} className={desktopTable}>
            <TableRow>
              <TableCell px={24}>
                <Subtitle variant="2" fontWeight={600} color="grey200">
                  {t(`clubs_page.comparative_section.tabs.title`)}
                </Subtitle>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell px={24} position="relative">
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_1.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_1.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_1.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_1.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-discount`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].fees}
                  </Body>
                </TableCell>
              ))}
            </TableRow>


            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_13.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_13.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_13.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_percentage" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_13.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`staking-${tier}`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].staking}
                  </Body>
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_3.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_3.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_3.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="rocket_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_3.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-airdrop`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].airdrop}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_4.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_4.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_4.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="document_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_4.title`
                        )}{" "}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell justifyContent="center" key={`${tier}-report`}>
                  <Icon
                    icon={tierValues[tier].hasReport ? "done" : "close"}
                    color={
                      tierValues[tier].hasReport
                        ? "var(--green-200-v2)"
                        : "black"
                    }
                    fontSize="24px"
                  />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_5.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_5.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_5.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="support_agent" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_5.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`support-${tier}`} justifyContent="center">
                  <Icon
                    icon={tierValues[tier].hasVip ? "done" : "close"}
                    color={
                      tierValues[tier].hasVip ? "var(--green-200-v2)" : "black"
                    }
                    fontSize="24px"
                  />
                </TableCell>
              ))}
            </TableRow><TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_12.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_12.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_12.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="add_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_12.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`taxreport-${tier}`} justifyContent="center">
                  {tierValues[tier].smartTrades ? (
                    <Body variant="1" fontWeight={600}>
                      {tierValues[tier].smartTrades}
                    </Body>
                  ) : (
                    <Icon icon="close" color="black" fontSize="24px" />
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_6.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_6.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_6.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="newsletter_fill" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_6.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`taxreport-${tier}`} justifyContent="center">
                  {tierValues[tier].taxReport ? (
                    <Body variant="1" fontWeight={600}>
                      {tierValues[tier].taxReport}
                    </Body>
                  ) : (
                    <Icon icon="close" color="black" fontSize="24px" />
                  )}
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_7.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_7.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_7.title"
                    className="toogle-tooltip"
                  >
                    <Icon icon="discount_percentage" fontSize="24px" />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_7.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`taxreport-${tier}`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].freeDeposit}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <Subtitle variant="2" fontWeight={600} color="grey200">
                  {t(`clubs_page.comparative_section.tabs.partner_title`)}
                </Subtitle>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_10.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_10.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_10.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/nord-vpn.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_10.title`
                      )}
                    />

                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_10.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-nordvpn`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].nordVpn}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24}>
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_2.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_2.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_2.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/freename.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_2.title`
                      )}
                    />

                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_2.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-freename`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].freename}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24} position="relative">
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_8.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_8.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_8.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/we-road.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_8.title`
                      )}
                    />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_8.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-weroad`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].weRoad}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24} position="relative">
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_11.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_11.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_11.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/saily.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_11.title`
                      )}
                    />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_11.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-saily`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].saily}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24} position="relative">
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_14.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_14.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_14.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/tiqets.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_14.title`
                      )}
                    />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_14.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-tiqets`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].tiqets}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell px={24} position="relative">
                <ClubTooltips
                  text={t(
                    "clubs_page.comparative_section.tabs.rows.row_15.description"
                  )}
                  id="clubs_page.comparative_section.tabs.rows.row_15.description"
                >
                  <TableCellContent
                    id="clubs_page.comparative_section.tabs.rows.row_15.title"
                    className="toogle-tooltip"
                  >
                    <StaticImage
                      src="../images/partner/built-different.png"
                      width={40}
                      height={40}
                      alt={t(
                        `clubs_page.comparative_section.tabs.rows.row_15.title`
                      )}
                    />
                    <HStack spacing={4} align="center">
                      <Body
                        variant="1"
                        fontWeight={600}
                        atoms={{
                          width: "full",
                        }}
                      >
                        {t(
                          `clubs_page.comparative_section.tabs.rows.row_15.title`
                        )}
                      </Body>{" "}
                      <Icon
                        icon="info_fill"
                        fontSize="16px"
                        color="var(--grey-200-v2)"
                      />
                    </HStack>
                  </TableCellContent>
                </ClubTooltips>
              </TableCell>
              {tiers.map(tier => (
                <TableCell key={`${tier}-builtdifferent`} justifyContent="center">
                  <Body variant="1" fontWeight={600}>
                    {tierValues[tier].builtDifferent}
                  </Body>
                </TableCell>
              ))}
            </TableRow>
            {language === "it" ? (
              <TableRow>
                <TableCell px={24}>
                  <ClubTooltips
                    text={t(
                      "clubs_page.comparative_section.tabs.rows.row_9.description"
                    )}
                    id="clubs_page.comparative_section.tabs.rows.row_9.description"
                  >
                    <TableCellContent
                      id="clubs_page.comparative_section.tabs.rows.row_9.title"
                      className="toogle-tooltip"
                    >
                      <StaticImage
                        src="../images/partner/hello-fresh.png"
                        width={40}
                        height={40}
                        alt={t(
                          `clubs_page.comparative_section.tabs.rows.row_9.title`
                        )}
                      />
                      <HStack spacing={4} align="center">
                        <Body
                          variant="1"
                          fontWeight={600}
                          atoms={{
                            width: "full",
                          }}
                        >
                          {t(
                            `clubs_page.comparative_section.tabs.rows.row_9.title`
                          )}
                        </Body>{" "}
                        <Icon
                          icon="info_fill"
                          fontSize="16px"
                          color="var(--grey-200-v2)"
                        />
                      </HStack>
                    </TableCellContent>
                  </ClubTooltips>
                </TableCell>
                {tiers.map(tier => (
                  <TableCell key={`hellofresh-${tier}`} justifyContent="center">
                    <Body variant="1" fontWeight={600}>
                      {tierValues[tier].helloFresh}
                    </Body>
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
          </Box>
        </Box>
        <TableRow>
          <Box />
          {tiers.map(tier => (
            <Box
              width="full"
              key={`${tier}-buttons`}
              display="flex"
              justifyContent="center"
            >
              <BlackButton
                maxWidth="calc(100% - 48px)"
                mt="24px"
                isFullWidth
                onClick={() => handleClick(tier)}
              >
                {t(
                  `clubs_page.comparative_section.tabs.columns.${tier}.cta_text`
                )}
              </BlackButton>
            </Box>
          ))}
        </TableRow>
      </CardGrid>
    </Box>
  )
}
